<template>
  <main id="ProductList">
    <div class="row">
      <header>
        <div class="title_box">
          <p class="sub_title"><b>PRODUCTS</b></p>
          <h3 class="title title_mark"><b>商品列表</b></h3>
        </div>
        <div class="select_box">
          <select v-model="active_category">
            <option value="all">所有商品</option>
            <option
              v-for="item in category"
              :value="item.category_id"
              :key="`category_${item.category_id}`"
            >
              {{ item.name }}
            </option>
          </select>
          <span class="material-icons-outlined"> expand_more </span>
        </div>
      </header>

      <div class="content_box">
        <ol class="card_list">
          <li class="card" v-for="item in active_products" :key="item.id">
            <router-link
              :to="`/product/page/${item.product_id}`"
              class="link_mark"
            >
              <div class="header">
                <h4>{{ item.name }}</h4>
                <span>{{ GetProductCategory(item.category) }}</span>
              </div>
              <div class="img_box">
                <img :src="item.thumbnail" />
                <img class="shadow" :src="item.thumbnail" />
              </div>
              <p>
                <span v-if="item.category.indexOf('11') != -1">單支</span> NT$
                {{ GetProductPrice(item) | formatPrice }}<span>起</span>
              </p>
            </router-link>
          </li>
        </ol>
      </div>

      <!-- <img
        v-for="index in Math.floor(products.length / 6)"
        :key="index.id"
        :style="`top:${index * 100}vh`"
        class="bg_text_1"
        src="/img/PERFORMANCE_TEXT.svg"
      /> -->
    </div>
    <MainFooter />
  </main>
</template>

<script>
import SmoothScrollResister from '@/common/SmoothScrollResister.js';
import ProductListGSAP from '@/common/GSAP/Products/product_list.js';
import MainFooter from '@/components/MainFooter';
import { getProductList } from '@/api/product.js';
import { GetMetaData } from '@/common/meta';
import { ReturnErrorPage } from '@/common/prerender_event';
export default {
  name: 'ProductList',
  mixins: [SmoothScrollResister, ProductListGSAP],
  components: {
    MainFooter,
  },
  data() {
    return {
      products: [],
      category: [],
      active_category: 'all',
      meta_data: null,
    };
  },
  methods: {
    async GetShopList() {
      getProductList().then((res) => {
        this.products = res.data.products;
        this.category = res.data.category;

        let category_exist = false;
        this.category.forEach((item) => {
          item.category_id == this.$route.params.type
            ? (category_exist = true)
            : '';
        });
        if (!category_exist && this.$route.params.type != 'all') {
          //return error
          ReturnErrorPage();
        } else {
          this.meta_data = GetMetaData(
            '商品列表',
            '歡迎使用MJP 茂建科技官方網站，各種客製化懸吊、賽道專用避震與前叉內管、油壓調整器，速可達小車到檔車與大型重機全部都有對應的品項。',
            ''
          );
          this.$nextTick(() => {
            this.RegisterGsap('ProductList');
            this.InitCard(-1);
            this.InitGsapMarker();
            this.$emit('init-cursor');
            window.prerenderReady = true;
          });
        }
      });
    },
    GetProductCategory(item_category) {
      let name = '';
      item_category.forEach((item) => {
        let category = this.category.filter(
          (category_item) => category_item.category_id == item
        )[0];
        if (
          category.name != '最新商品' &&
          category.name != '熱門商品' &&
          name == ''
        ) {
          name = category.name;
        }
      });
      return name;
    },
    GetProductPrice(item) {
      if (item.price_type == 'P') {
        return item.price;
      } else {
        let price = 9999999;
        item.option_combine.forEach((option) => {
          parseInt(option.price) < parseInt(price)
            ? (price = option.price)
            : '';
        });
        return price;
      }
    },
  },
  watch: {
    active_category() {
      this.$router.push(`/products/list/${this.active_category}/`);
    },
  },
  computed: {
    active_products() {
      let products = this.products;
      if (this.$route.params.type != 'all') {
        products = this.products.filter((item) => {
          return item.category.indexOf(this.$route.params.type) != -1;
        });
      }
      return products;
    },
  },
  mounted() {
    this.active_category = this.$route.params.type;
    this.$emit('init-cursor');
    this.$emit('toggle-go-top', true);
  },
  created() {
    this.active_category = this.$route.params.type;
    this.GetShopList();
  },
  filters: {
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
